/* eslint-disable @typescript-eslint/ban-types */
import clsx from 'clsx'
import { ReactElement } from 'react'
import {
  Control,
  Controller,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form'
import FormFieldType from '../../enums/form-field.enum'
import ErrorMessage from './ErrorMessage'
import FormField from './FormField'

interface IProps {
  control: Control<FieldValues, object> | undefined
  name: string
  type: FormFieldType
  placeholder?: string
  rules?: RegisterOptions
  defaultValue?: string | number | boolean | undefined
  className?: string
  label?: string
  containerClassName?: string
}

const ControlledField = ({
  control,
  name,
  type,
  placeholder = '',
  rules,
  defaultValue = '',
  className = '',
  containerClassName = '',
  label = '',
}: IProps): ReactElement => {
  const containerClass = clsx(
    'flex flex-col flex-grow-0 flex-shrink-0',
    containerClassName,
  )

  return (
    <div className={containerClass}>
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        render={({
          field: { onChange, value, onBlur },
          fieldState: { error },
        }) => {
          return (
            <>
              <FormField
                label={label}
                error={error}
                className={className}
                onBlur={onBlur}
                onChange={onChange}
                placeholder={placeholder}
                type={type}
                value={value}
              />

              {!!error && (
                <ErrorMessage
                  message={error.message as string}
                  className="mt-1"
                />
              )}
            </>
          )
        }}
        rules={rules}
      />
    </div>
  )
}

export default ControlledField
