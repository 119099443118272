import CategoryPreference from "../enums/category-preference.enum"

const CATEGORY_OPTIONS = [
  {
    id: CategoryPreference.BEACH,
    label: '🏖️ Plage',
  },
  {
    id: CategoryPreference.MOUNTAIN,
    label: '⛰️ Montagne',
  },
]

export default CATEGORY_OPTIONS
