import { Listbox } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import clsx from 'clsx'
import { Fragment } from 'react'
import IOption from '../../interfaces/option.interface';

interface IProps {
  options: IOption[];
  value?: string;
  onChange?: (value: string | number) => void;
  label?: string
}

const Select = ({ options, value, onChange, label }: IProps) => {
  const optionSelected = options.find((option: IOption) => option.id === value)

  const handleChange = (option: IOption) => onChange ? onChange(option.id) : null

  const buttonClass = clsx(
    'w-full flex gap-3 h-8 pl-3 leading-5 bg-white text-left items-center py-1 flex justify-between border border-gray-border px-4 h-[46px] rounded-3xl',
  )

  return (
    <div className="w-full">
      <div className="italic font-opensans mb-1 ml-2">{label}</div>
      <Listbox value={optionSelected} onChange={handleChange}>
        <Listbox.Button className={buttonClass}>
          <div>{optionSelected ? optionSelected.label : ''}</div>
          <SelectorIcon className="w-5 h-5" />
        </Listbox.Button>
        <Listbox.Options className="absolute z-50 py-1 overflow-auto text-base leading-6 bg-white rounded-md shadow-xl max-h-60 focus:outline-none w-60 border border-gray-main">
          {options.map((option: any) => (
            <Listbox.Option key={option.id} value={option} as={Fragment}>
              {({ active, selected }) => (
                <li>
                  <div
                    className={`${active ? 'text-white bg-blue-600' : 'text-gray-900'
                      } cursor-default select-none relative py-1 pl-8 pr-4 flex justify-between`}
                  >
                    {option.label}
                    {selected && <CheckIcon className="w-5 h-5" />}
                  </div>
                </li>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  )
}

export default Select
