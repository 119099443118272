import { useQuery } from '@apollo/client'
import React, { useContext } from 'react'
import Card from '../components/common/Card'
import ErrorMessage from '../components/common/ErrorMessage'
import Loading from '../components/common/Loading'
import PartnerItem from '../components/partner/PartnerItem'
import { LayoutContext } from '../contexts/layout.context'
import Modal from '../enums/modal.enum'
import { GET_ALL_PARTNERS_QUERY } from '../graphql/partner.graphql'
import IPartner from '../interfaces/partner.interface'

const Partners = () => {
  const { openModal } = useContext(LayoutContext)
  const getAllPartnersQuery = useQuery(GET_ALL_PARTNERS_QUERY)

  const addPartnerModal = () => openModal(Modal.ADD_PARTNER)

  if (getAllPartnersQuery.loading) {
    return <Loading />
  }

  if (getAllPartnersQuery.error) {
    return <ErrorMessage message={getAllPartnersQuery.error.message} />
  }

  const partners = getAllPartnersQuery.data.getAllPartners

  return (
    <div>
      <div className="flex items-end justify-between h-12">
        <h1>{`Partenaires (${partners.length})`}</h1>
        <button className="btn btn-primary" onClick={addPartnerModal}>
          {'Ajout un partenaire'}
        </button>
      </div>

      <div className="mt-8">
        <Card className="flex flex-col gap-4">
          {partners.map((partner: IPartner) => (
            <PartnerItem partner={partner} key={partner._id} />
          ))}
        </Card>
      </div>
    </div>
  )
}

export default Partners
