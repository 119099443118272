enum FormFieldType {
  TEXT = 'TEXT',
  MULTI_TEXT = 'MULTI_TEXT',
  LONG_TEXT = 'LONG_TEXT',
  PASSWORD = 'PASSWORD',
  IMAGE = 'IMAGE',
  AUTO_COMPLETE = 'AUTO_COMPLETE',
  POSITION = 'POSITION',
  CATEGORY = 'CATEGORY',
  RICH_TEXT = 'RICH_TEXT',
}

export default FormFieldType
