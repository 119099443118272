import React from 'react'

interface IProps {
  className?: string
  withoutText?: boolean
}

const Logo = ({ className = '', withoutText = false }: IProps) => {
  if (withoutText) {
    return (
      <svg
        width="482"
        height="482"
        className={className}
        viewBox="0 0 482 482"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31.6334 462.154C31.6334 472.344 39.9963 481.077 50.5407 481.077H106.899L31.997 423.581L31.6334 462.154Z"
          fill="#6D52F7"
        />
        <path
          d="M123.625 275.837C121.807 279.476 118.171 281.659 114.171 281.659C110.172 281.659 106.536 279.476 104.354 275.837C86.901 244.178 62.176 217.249 31.6334 197.962V395.924L142.896 481.077H200.709V194.687C200.709 194.687 200.345 195.051 199.981 195.051C167.984 215.066 141.805 242.358 123.625 275.837Z"
          fill="#6D52F7"
        />
        <path
          d="M462.139 481.077C472.32 481.077 481.046 472.707 481.046 462.154V423.945L408.689 480.713C408.689 481.077 462.139 481.077 462.139 481.077Z"
          fill="#6D52F7"
        />
        <path
          d="M410.507 332.969H297.063V291.121H222.525V481.077H373.056L481.046 395.924V291.121H410.507V332.969Z"
          fill="#6D52F7"
        />
        <path
          d="M377.419 112.082H181.074C198.891 127.001 211.98 147.38 218.161 169.578C221.07 179.767 222.525 190.684 222.525 201.237V252.547H297.063V219.068H410.507V252.547H481.41V216.157C481.41 158.661 434.869 112.082 377.419 112.082Z"
          fill="#6D52F7"
        />
        <path
          d="M388.327 241.63H318.879V311.135H388.327V241.63Z"
          fill="#6D52F7"
        />
        <path
          d="M45.4503 180.495C45.4503 180.495 45.8139 180.495 45.8139 180.859C51.268 184.498 56.3584 188.137 61.0852 191.776C81.8105 207.787 99.6271 227.074 114.171 248.908C128.715 227.074 146.168 207.787 166.894 191.776C171.984 187.773 177.074 184.134 182.165 180.859C182.165 180.859 182.528 180.859 182.528 180.495C177.438 177.22 172.348 173.581 167.621 169.942C146.532 153.93 128.715 134.279 114.171 112.082C99.6271 134.279 81.447 153.93 60.7216 169.942C55.6312 173.217 50.5407 176.856 45.4503 180.495Z"
          fill="#6D52F7"
        />
        <path
          d="M75.6293 91.3392C59.6308 82.6055 46.5411 69.5051 37.8147 53.4935C29.0882 69.5051 15.9985 82.6055 0 91.3392C15.9985 100.073 29.0882 113.173 37.8147 129.185C46.5411 113.173 59.6308 100.073 75.6293 91.3392Z"
          fill="#6D52F7"
        />
        <path
          d="M155.622 75.6914C164.348 59.6798 177.438 46.5793 193.437 37.8457C177.438 29.1121 164.348 16.0116 155.622 0C146.895 16.0116 133.806 29.1121 117.807 37.8457C133.806 46.5793 146.895 59.6798 155.622 75.6914Z"
          fill="#6D52F7"
        />
      </svg>
    )
  }
  return (
    <svg
      className={className}
      width="361"
      height="90"
      viewBox="0 0 361 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.08 86.38C6.08 88.31 7.65 89.89 9.59 89.89H20.11L6.09 79.14V86.38H6.08Z"
        fill="#6D59F2"
      />
      <path
        d="M23.27 51.52C22.92 52.18 22.22 52.59 21.47 52.59C20.72 52.59 20.02 52.18 19.66 51.52C16.41 45.58 11.75 40.59 6.08 36.95V73.96L26.88 89.89H37.73V36.4C37.73 36.4 37.63 36.46 37.58 36.49C31.58 40.16 26.66 45.32 23.26 51.52H23.27Z"
        fill="#6D59F2"
      />
      <path
        d="M86.62 89.88C88.55 89.88 90.13 88.31 90.13 86.37V79.25L76.6 89.88H86.62Z"
        fill="#6D59F2"
      />
      <path
        d="M76.92 62.23H55.7V54.43H41.75V89.88H69.93L90.13 74.02V54.43H76.92V62.23Z"
        fill="#6D59F2"
      />
      <path
        d="M33.95 20.95C37.3 23.76 39.72 27.53 40.91 31.69C41.46 33.61 41.75 35.61 41.75 37.64V47.24H55.7V41H76.93V47.24H90.19V40.4C90.19 29.67 81.46 20.95 70.73 20.95H33.95Z"
        fill="#6D59F2"
      />
      <path d="M72.8 45.12H59.82V58.11H72.8V45.12Z" fill="#6D59F2" />
      <path
        d="M8.63 33.69C8.63 33.69 8.69 33.72 8.71 33.75C9.7 34.4 10.66 35.09 11.58 35.81C15.44 38.8 18.77 42.42 21.46 46.52C24.15 42.42 27.48 38.8 31.34 35.81C32.27 35.09 33.23 34.4 34.21 33.75C34.24 33.73 34.27 33.72 34.29 33.69C33.32 33.06 32.38 32.39 31.47 31.69C27.55 28.67 24.17 25.02 21.46 20.86C18.74 25.02 15.36 28.67 11.44 31.69C10.53 32.39 9.59 33.06 8.62 33.69H8.63Z"
        fill="#6D59F2"
      />
      <path
        d="M14.29 17.09C11.31 15.46 8.87 13.01 7.24 10.04C5.61 13.02 3.16 15.46 0.190002 17.09C3.17 18.72 5.61 21.17 7.24 24.14C8.87 21.16 11.32 18.72 14.29 17.09Z"
        fill="#6D59F2"
      />
      <path
        d="M29.23 14.1C30.86 11.12 33.31 8.68 36.28 7.05C33.3 5.42 30.86 2.97 29.23 0C27.6 2.98 25.15 5.42 22.18 7.05C25.16 8.68 27.6 11.13 29.23 14.1Z"
        fill="#6D59F2"
      />
      <path
        d="M140.814 43.136C141.436 42.2318 141.925 41.3276 142.302 40.4423C142.679 39.5569 142.867 38.5963 142.867 37.5602C142.867 37.24 142.83 36.8633 142.773 36.4488C142.717 36.0344 142.641 35.6954 142.566 35.394L141.888 34.7158H108.753V44.2285H127.948L110.053 70.8075C109.432 71.7494 108.942 72.6536 108.565 73.5013C108.188 74.3489 108 75.3096 108 76.3833C108 76.7224 108.038 77.0615 108.094 77.4382C108.151 77.8149 108.226 78.1728 108.301 78.5496L108.98 79.2277H143.093V69.715H122.9L140.795 43.136H140.814Z"
        fill="#1F1A4C"
      />
      <path
        d="M180.259 34.5463C178.243 34.3391 176.36 34.2449 174.627 34.2449C171.613 34.2449 168.9 34.471 166.47 34.923C164.04 35.3751 161.93 36.1851 160.141 37.3342C158.351 38.4832 156.863 40.1032 155.658 42.1565C154.471 44.2097 153.604 46.8469 153.058 50.068L148.104 79.2466H159.105L160.989 67.869H174.438V79.2466H185.382V46.0557C185.382 45.2269 185.382 44.172 185.42 42.9099C185.439 41.629 185.477 40.3669 185.552 39.1425C185.609 37.8993 185.722 36.9197 185.891 36.1663L185.759 35.4316C184.102 35.0549 182.274 34.7723 180.259 34.5651V34.5463ZM162.439 59.0909L163.871 50.4447C164.153 48.8436 164.605 47.525 165.208 46.489C165.811 45.4529 166.677 44.6806 167.826 44.172C168.975 43.6634 170.52 43.3997 172.46 43.3997C173.12 43.3997 173.835 43.4374 174.589 43.4939H174.627C174.627 43.6257 174.589 43.7388 174.589 43.8706C174.495 44.8878 174.438 45.9804 174.438 47.1859V59.1098H162.439V59.0909Z"
        fill="#1F1A4C"
      />
      <path
        d="M225.11 63.7248C226.937 62.1237 228.387 60.1647 229.461 57.8854C230.535 55.6061 231.062 53.0066 231.062 50.1245C231.062 46.9976 230.384 44.2662 229.047 41.9681C227.709 39.67 225.713 37.8804 223.057 36.6184C220.4 35.3563 217.048 34.7347 213.016 34.7347H194.839V79.2466H205.839V68.679H211.472C211.962 68.679 212.451 68.6413 212.96 68.6225L220.325 79.2466H232.437V78.6249L222.454 65.665C223.396 65.0999 224.281 64.4595 225.11 63.7248ZM205.858 43.8895H212.96C215.107 43.8895 216.821 44.4169 218.121 45.4906C219.421 46.5643 220.061 48.2408 220.061 50.5012C220.061 52.1024 219.76 53.6094 219.138 55.0221C218.517 56.4161 217.556 57.5651 216.256 58.4505C214.957 59.3358 213.28 59.7879 211.227 59.7879H205.858V43.8895Z"
        fill="#1F1A4C"
      />
      <path
        d="M250.295 34.7347H239.294V79.2466H267.229V69.7338H250.295V34.7347Z"
        fill="#1F1A4C"
      />
      <path
        d="M305.468 36.9009C302.078 34.9607 297.971 34 293.206 34C288.44 34 284.277 34.9607 280.905 36.9009C277.533 38.8411 274.953 41.5348 273.182 45.0008C271.411 48.4668 270.526 52.4603 270.526 57C270.526 61.5397 271.411 65.5897 273.182 69.0557C274.953 72.5217 277.533 75.1966 280.905 77.1179C284.277 79.0393 288.383 80 293.206 80C298.028 80 302.078 79.0393 305.468 77.0991C308.859 75.1589 311.459 72.4652 313.229 68.9992C315 65.5332 315.885 61.5397 315.885 57C315.885 52.4603 315 48.4668 313.229 45.0008C311.459 41.5348 308.878 38.8411 305.468 36.9009ZM303.359 64.1392C302.511 66.2301 301.249 67.8313 299.554 68.9992C297.858 70.1482 295.749 70.7322 293.187 70.7322C290.625 70.7322 288.496 70.1482 286.782 68.9992C285.068 67.8501 283.787 66.2301 282.958 64.1392C282.111 62.0672 281.696 59.6749 281.696 57C281.696 54.3252 282.111 51.9328 282.958 49.8608C283.806 47.7887 285.087 46.1687 286.782 45.0008C288.496 43.8518 290.625 43.2678 293.187 43.2678C295.749 43.2678 297.858 43.8518 299.554 45.0008C301.249 46.1499 302.511 47.7699 303.359 49.8608C304.206 51.9328 304.621 54.3252 304.621 57C304.621 59.6749 304.206 62.0672 303.359 64.1392Z"
        fill="#1F1A4C"
      />
      <path
        d="M353.428 63.7248C355.255 62.1237 356.705 60.1647 357.779 57.8854C358.853 55.6061 359.38 53.0066 359.38 50.1245C359.38 46.9976 358.702 44.2662 357.364 41.9681C356.027 39.67 354.03 37.8804 351.374 36.6184C348.718 35.3563 345.365 34.7347 341.334 34.7347H323.156V79.2466H334.157V68.679H339.79C340.279 68.679 340.769 68.6413 341.278 68.6225L348.643 79.2466H360.755V78.6249L350.772 65.665C351.713 65.0999 352.599 64.4595 353.428 63.7248ZM334.176 43.8895H341.278C343.425 43.8895 345.139 44.4169 346.439 45.4906C347.739 46.5643 348.379 48.2408 348.379 50.5012C348.379 52.1024 348.078 53.6094 347.456 55.0221C346.835 56.4161 345.874 57.5651 344.574 58.4505C343.274 59.3358 341.598 59.7879 339.545 59.7879H334.176V43.8895Z"
        fill="#1F1A4C"
      />
    </svg>
  )
}

export default Logo
