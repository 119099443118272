import clsx from 'clsx'
import { ReactElement } from 'react'

interface IProps {
  message: string
  className?: string
}

function renderErrorMessage(message: string): string {
  switch (message) {
    default:
      return message
  }
}

const ErrorMessage = ({ message, className = '' }: IProps): ReactElement => {
  const errorClass = clsx('text-sm text-red-500 font-sofia', className)
  return <div className={errorClass}>{renderErrorMessage(message)}</div>
}

export default ErrorMessage
