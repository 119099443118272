import { useMutation } from '@apollo/client'
import clsx from 'clsx'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { LayoutContext } from '../../contexts/layout.context'
import {
  EDIT_CIRCUIT_MUTATION,
  GET_ALL_CIRCUITS_QUERY,
} from '../../graphql/circuit.graphql'
import ICircuit from '../../interfaces/circuit.interface'

import CircuitForm from '../circuit/CircuitForm'

interface IProps {
  circuit: ICircuit
}

const EditCircuitModal = ({ circuit }: IProps) => {
  const initialValue = {
    name: circuit.name,
    description: circuit.description,
    category: circuit.category,
    sector: circuit.sector,
  }
  const { handleSubmit, control } = useForm({
    defaultValues: initialValue,
  })
  const { closeModal } = useContext(LayoutContext)
  const [editCircuitMutation, editCircuitMutationResult] = useMutation(
    EDIT_CIRCUIT_MUTATION,
    {
      refetchQueries: [
        {
          query: GET_ALL_CIRCUITS_QUERY,
        },
      ],
      onCompleted: closeModal,
    },
  )

  const onSubmit = async (values: { [x: string]: any }) => {
    editCircuitMutation({
      variables: {
        editCircuitInput: {
          ...values,
          circuitId: circuit._id,
        },
      },
    })
  }

  const buttonClass = clsx('btn btn-primary', {
    'opacity-30': editCircuitMutationResult.loading,
  })

  return (
    <div>
      <h2 className="title">{'Modifier le circuit'}</h2>

      <div className="mt-8">
        <div className="text-light">
          <form onSubmit={handleSubmit(onSubmit)}>
            <CircuitForm control={control as any} />

            <div className="flex justify-end mt-6">
              <button
                className={buttonClass}
                disabled={editCircuitMutationResult.loading}
              >
                {editCircuitMutationResult.loading
                  ? 'Enregistrement...'
                  : 'Enregistrer'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default EditCircuitModal
