import { gql } from '@apollo/client'

export const CIRCUIT_FRAGMENT = gql`
  fragment circuitFragment on Circuit {
    _id
    name
    description
    category
    sector
    places {
      _id
      name
      tags
      description
      photo {
        url
      }
      offers {
        _id
        name
      }
    }
  }
`

export const GET_ALL_CIRCUITS_QUERY = gql`
  query getAllCircuits {
    getAllCircuits {
      ...circuitFragment
    }
  }
  ${CIRCUIT_FRAGMENT}
`

export const GET_CIRCUIT_QUERY = gql`
  query getCircuit($getCircuitInput: GetCircuitInput!) {
    getCircuit(getCircuitInput: $getCircuitInput) {
      ...circuitFragment
    }
  }
  ${CIRCUIT_FRAGMENT}
`

export const ADD_CIRCUIT_MUTATION = gql`
  mutation addCircuit($addCircuitInput: AddCircuitInput!) {
    addCircuit(addCircuitInput: $addCircuitInput) {
      _id
    }
  }
`

export const EDIT_CIRCUIT_MUTATION = gql`
  mutation editCircuit($editCircuitInput: EditCircuitInput!) {
    editCircuit(editCircuitInput: $editCircuitInput) {
      _id
    }
  }
`

export const EDIT_PLACES_CIRCUIT_MUTATION = gql`
  mutation editPlacesCircuit($editPlacesCircuitInput: EditPlacesCircuitInput!) {
    editPlacesCircuit(editPlacesCircuitInput: $editPlacesCircuitInput) {
      ...circuitFragment
    }
  }
  ${CIRCUIT_FRAGMENT}
`
