import React, { ReactNode } from 'react'

interface IProps {
  children: ReactNode
}

const InnerCard = ({ children }: IProps) => {
  return <div className="px-6 py-5 border rounded-2xl bg-white">{children}</div>
}

export default InnerCard
