import IPlace from '../../interfaces/place.interface';

interface IProps {
  place: IPlace
}

const PlacePhoto = ({place}: IProps) => {
  return (
    <div
      className="flex-grow-0 flex-shrink-0 w-40 h-40 rounded-2xl"
      style={{
        backgroundImage: place.photo ? `url("${place.photo.url}")` : '',
        backgroundSize: 'cover',
      }}
    />
  );
};

export default PlacePhoto;
