enum AcquisitionChannel {
  FRIENDS = 'FRIENDS',
  SOCIAL_NETWORK = 'SOCIAL_NETWORK',
  WORD_OF_MOUTH = 'WORD_OF_MOUTH',
  EVENT = 'EVENT',
  INTERNET = 'INTERNET',
  OTHER = 'OTHER',
}

export default AcquisitionChannel;
