import { useState } from 'react'
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import InputLabel from './InputLabel';

interface IProps {
  value?: string,
  onChange?: (v: string) => null,
  placeholder?: string,
  label?: string,
  inputClass?: string,
  disabled?: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
}

const RichTextInput = ({ value = "", onChange, placeholder, disabled, label }: IProps) => {
  const blocksFromHTML = convertFromHTML(value);
  const state = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap,
  );
  const [tempState, setTempState] = useState(EditorState.createWithContent(state))

  // return null
  if (disabled) {
    return <div className="w-full">
      {label && <InputLabel label={label} className="mb-1 ml-2" />}

      {/* Warning to XSS here, content should be sanitized on server side */}
      <div dangerouslySetInnerHTML={{ __html: value }} className="message-email-content mt-6" />
    </div>
  }

  return (
    <div className="w-full">
      {label && <InputLabel label={label} className="mb-1 ml-2" />}
      <Editor
        editorState={tempState}
        onBlur={() => {
          const currentContent = tempState.getCurrentContent()
          const rawContent = convertToRaw(currentContent)
          const valueHtml = draftToHtml(rawContent)
          onChange && onChange(valueHtml)
        }}
        placeholder={placeholder}
        onEditorStateChange={setTempState}
        editorClassName="px-4 py-3 border outline-none rounded-3xl text-sm border-gray-border"
      />
    </div>
  );
};

export default RichTextInput;
