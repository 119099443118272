enum Modal {
  ADD_PLACE = 'ADD_PLACE',
  EDIT_PLACE = 'EDIT_PLACE',
  ADD_CIRCUIT = 'ADD_CIRCUIT',
  EDIT_CIRCUIT = 'EDIT_CIRCUIT',
  EDIT_CIRCUIT_PLACES = 'EDIT_CIRCUIT_PLACES',
  ADD_PLACE_TO_CIRCUIT = 'ADD_PLACE_TO_CIRCUIT',
  ADD_PARTNER = 'ADD_PARTNER',
  EDIT_PARTNER = 'EDIT_PARTNER',
  EDIT_OFFER = 'EDIT_OFFER',
  ADD_OFFER = 'ADD_OFFER',
}

export default Modal
