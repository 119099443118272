import { useMutation, useQuery } from '@apollo/client'
import { Fragment, useEffect, useState } from 'react'
import { GET_ALL_PLACES_QUERY } from '../../graphql/place.graphql'
import ErrorMessage from '../common/ErrorMessage'
import Loading from '../common/Loading'
import { Combobox, Transition } from '@headlessui/react'
import ICircuit from '../../interfaces/circuit.interface'
import IPlace from '../../interfaces/place.interface'
import { CheckIcon, SelectorIcon, XIcon } from '@heroicons/react/solid'
import PlaceItem from '../place/PlaceItem'
import Card from '../common/Card'
import { EDIT_PLACES_CIRCUIT_MUTATION } from '../../graphql/circuit.graphql'

interface IProps {
  circuit: ICircuit
  className?: string
}

const AddPlaceToCircuitForm = ({ circuit, className = '' }: IProps) => {
  const [selectedPlaces, setSelectedPlaces] = useState<IPlace[]>(circuit.places)

  const [query, setQuery] = useState('')
  const [editPlacesCircuitMutation] = useMutation(EDIT_PLACES_CIRCUIT_MUTATION)
  const getAllPlacesQuery = useQuery(GET_ALL_PLACES_QUERY)

  useEffect(() => {
    editPlacesCircuitMutation({
      variables: {
        editPlacesCircuitInput: {
          circuitId: circuit._id,
          places: selectedPlaces.map((p: IPlace) => p._id),
        },
      },
    })
  }, [selectedPlaces, editPlacesCircuitMutation, circuit._id])

  if (getAllPlacesQuery.loading) {
    return <Loading />
  }

  if (getAllPlacesQuery.error) {
    return <ErrorMessage message={getAllPlacesQuery.error.message} />
  }

  const places = getAllPlacesQuery.data.getAllPlaces

  const filteredPlaces =
    query === ''
      ? places
      : places.filter((place: IPlace) =>
        place.name.toLowerCase().includes(query.toLowerCase()),
      )

  // const buttonActive =
  //   !editPlacesCircuitMutationResult.loading &&
  //   circuit.places !== selectedPlaces
  // const buttonClass = clsx('btn btn-primary', {
  //   'opacity-30': !buttonActive,
  // })

  const addToCircuit = (place: IPlace) => {
    setSelectedPlaces([...selectedPlaces, place])
  }

  // const saveCircuitPlaces = () =>
  //   editPlacesCircuitMutation({
  //     variables: {
  //       editPlacesCircuitInput: {
  //         places: selectedPlaces.map((place) => place._id),
  //       },
  //     },
  //   })

  return (
    <div className={className}>
      <Card>
        <div>
          <h2 className="title">{`Ajouter une étape`}</h2>
          <div className="flex gap-2 mt-4">
            <div className="w-72">
              <Combobox value={null} onChange={addToCircuit}>
                <div className="relative mt-1">
                  <div className="relative w-full overflow-hidden text-left bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                    <Combobox.Input
                      className="w-full py-2 pl-3 pr-10 text-sm leading-5 text-gray-border border-none focus:ring-0"
                      // displayValue={(place: IPlace) =>
                      //   place ? place.name : ''
                      // }
                      value={"Ajouter une étape"}
                      onChange={(event) => setQuery(event.target.value)}
                    />
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                      <SelectorIcon
                        className="w-5 h-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </Combobox.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setQuery('')}
                  >
                    <Combobox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {filteredPlaces.length === 0 && query !== '' ? (
                        <div className="relative px-4 py-2 text-gray-700 cursor-default select-none">
                          {'Nothing found.'}
                        </div>
                      ) : (
                        filteredPlaces.map((place: IPlace) => (
                          <Combobox.Option
                            key={place._id}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${active
                                ? 'bg-teal-600 text-white'
                                : 'text-gray-900'
                              }`
                            }
                            value={place}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                    }`}
                                >
                                  {place ? place.name : ''}
                                </span>
                                {selected ? (
                                  <span
                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                      }`}
                                  >
                                    <CheckIcon
                                      className="w-5 h-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Combobox.Option>
                        ))
                      )}
                    </Combobox.Options>
                  </Transition>
                </div>
              </Combobox>
            </div>
          </div>
        </div>

        <div className="flex items-end justify-between h-12">
          <h2 className="title">{`Point d'intérêt (${selectedPlaces.length})`}</h2>
        </div>
        <div className="flex flex-col gap-4 mt-4">
          {selectedPlaces.map((place: IPlace) => {
            const handleRemove = () =>
              setSelectedPlaces(
                selectedPlaces.filter((p) => p._id !== place._id),
              )
            return (
              <PlaceItem
                key={place._id}
                place={place}
                footer={
                  <div className="flex justify-end gap-4 mt-4">
                    <button
                      className="flex btn btn-red-outline cursor-pointer"
                      onClick={handleRemove}
                    >
                      <XIcon className="w-6 h-6" />
                      <span>{' Supprimer'}</span>
                    </button>
                  </div>
                }
              />
            )
          })}

          {/* <div className="flex justify-end">
            <button
              className={buttonClass}
              onClick={saveCircuitPlaces}
              disabled={!buttonActive}
            >
              {'Sauvegarder'}
            </button>
          </div> */}
        </div>
      </Card>
    </div>
  )
}

export default AddPlaceToCircuitForm
