import { gql } from '@apollo/client'

export const GET_KPIS_QUERY = gql`
  query getKpis {
    getKpis {
      usersCount
      circuitsCount
      placesCount
      weeklyScansCount
    }
  }
`
