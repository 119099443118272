import { useMutation } from '@apollo/client'
import clsx from 'clsx'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { LayoutContext } from '../../contexts/layout.context'
import { EDIT_OFFER_MUTATION } from '../../graphql/offer.graphql'
import { GET_PARTNER_QUERY } from '../../graphql/partner.graphql'
import IOffer from '../../interfaces/offer.interface'
import { fileToBase64 } from '../../lib/helper'
import OfferForm from '../offer/OfferForm'

interface IProps {
  offer: IOffer
}

const EditOfferModal = ({ offer }: IProps) => {
  const { handleSubmit, control } = useForm({
    defaultValues: {
      name: offer.name,
      description: offer.description,
    }
  })
  const { closeModal } = useContext(LayoutContext)
  const [editOfferMutation, editOfferMutationResult] = useMutation(
    EDIT_OFFER_MUTATION,
    {
      refetchQueries: [
        {
          query: GET_PARTNER_QUERY,
          variables: {
            getPartnerInput: {
              partnerId: offer.partner._id,
            }
          },
        },
      ],
      onCompleted: closeModal,
    },
  )

  const onSubmit = async (values: { [x: string]: any }) => {
    editOfferMutation({
      variables: {
        editOfferInput: {
          ...values,
          offerId: offer._id,
          photo: await fileToBase64(values.photo),
        },
      },
    })
  }

  const buttonClass = clsx('btn btn-primary', {
    'opacity-30': editOfferMutationResult.loading,
  })

  return (
    <div>
      <h2 className="title">{"Édition d'une offre"}</h2>

      <div className="mt-8">
        <div className="text-light">
          <form onSubmit={handleSubmit(onSubmit)}>
            <OfferForm control={control as any} />

            <div className="flex justify-end mt-6">
              <button
                className={buttonClass}
                disabled={editOfferMutationResult.loading}
              >
                {editOfferMutationResult.loading
                  ? 'Enregistrement...'
                  : 'Enregistrer'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default EditOfferModal
