import { useQuery } from '@apollo/client'
import React, { useContext } from 'react'
import CircuitItem from '../components/circuit/CircuitItem'
import Card from '../components/common/Card'
import ErrorMessage from '../components/common/ErrorMessage'
import Loading from '../components/common/Loading'
import { LayoutContext } from '../contexts/layout.context'
import Modal from '../enums/modal.enum'
import { GET_ALL_CIRCUITS_QUERY } from '../graphql/circuit.graphql'
import ICircuit from '../interfaces/circuit.interface'

const Circuit = () => {
  const { openModal } = useContext(LayoutContext)

  const getAllCircuitsQuery = useQuery(GET_ALL_CIRCUITS_QUERY)

  if (getAllCircuitsQuery.loading) {
    return <Loading />
  }

  if (getAllCircuitsQuery.error) {
    return <ErrorMessage message={getAllCircuitsQuery.error.message} />
  }

  const circuits = getAllCircuitsQuery.data.getAllCircuits

  const addCircuitModal = () => {
    openModal(Modal.ADD_CIRCUIT)
  }
  return (
    <div>
      <div className="flex items-end justify-between h-12">
        <h1>{`Circuits (${circuits.length})`}</h1>
        <button className="btn btn-primary" onClick={addCircuitModal}>
          {'Ajout un circuit'}
        </button>
      </div>

      <div className="mt-8">
        <Card className="flex flex-col gap-4">
          {circuits.map((circuit: ICircuit) => (
            <CircuitItem circuit={circuit} key={circuit._id} />
          ))}
        </Card>
      </div>
    </div>
  )
}

export default Circuit
