import { useQuery } from '@apollo/client'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Card from '../components/common/Card'
import ErrorMessage from '../components/common/ErrorMessage'
import Loading from '../components/common/Loading'
import PlaceItem from '../components/place/PlaceItem'
import { LayoutContext } from '../contexts/layout.context'
import Modal from '../enums/modal.enum'
import { GET_ALL_PLACES_QUERY } from '../graphql/place.graphql'
import IPlace from '../interfaces/place.interface'

const Places = () => {
  const navigation = useNavigate()
  const { openModal } = useContext(LayoutContext)
  const getAllPlacesQuery = useQuery(GET_ALL_PLACES_QUERY)

  if (getAllPlacesQuery.loading) {
    return <Loading />
  }

  if (getAllPlacesQuery.error) {
    return <ErrorMessage message={getAllPlacesQuery.error.message} />
  }

  const places = getAllPlacesQuery.data.getAllPlaces

  const addPlaceModal = () => {
    openModal(Modal.ADD_PLACE)
  }

  return (
    <div>
      <div className="flex items-end justify-between h-12">
        <h1>{`Points d'interêt (${places.length})`}</h1>
        <button className="btn btn-primary" onClick={addPlaceModal}>
          {"Ajout un point d'intérêt"}
        </button>
      </div>

      <div className="mt-8">
        <Card className="flex flex-col gap-4">
          {places.map((place: IPlace) => {
            const handleClick = () => navigation(`/points-dinteret/${place._id}`)
            // const handleEdit = () => openModal(Modal.EDIT_PLACE, { place })

            return (
              <PlaceItem
                place={place}
                key={place._id}
                footer={
                  <div className="flex justify-end gap-4 mt-4">
                    <button
                      className="flex btn btn-primary-outline"
                      onClick={handleClick}
                    >
                      <span>{' Voir les détails'}</span>
                    </button>
                    {/* <button
                      className="flex btn btn-primary-outline"
                      onClick={handleEdit}
                    >
                      <PencilIcon className="w-6 h-6" />
                      <span>{' Modifier'}</span>
                    </button> */}
                  </div>
                }
              />
            )
          })}
        </Card>
      </div>
    </div>
  )
}

export default Places
