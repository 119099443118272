import { useMutation } from '@apollo/client'
import clsx from 'clsx'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { LayoutContext } from '../../contexts/layout.context'

import {
  EDIT_PLACE_MUTATION,
  GET_ALL_PLACES_QUERY,
} from '../../graphql/place.graphql'
import IPlace from '../../interfaces/place.interface'
import { fileToBase64 } from '../../lib/helper'

import PlaceForm from '../place/PlaceForm'

interface IProps {
  place: IPlace
}

const EditPlaceModal = ({ place }: IProps) => {
  const [lng, lat] = place.location.coordinates
  const initialValue = {
    name: place.name,
    description: place.description,
    tags: place.tags,
    photo: null,
    location: { lng, lat },
  }
  const { handleSubmit, control } = useForm({
    defaultValues: initialValue,
  })
  const { closeModal } = useContext(LayoutContext)
  const [editPlaceMutation, editPlaceMutationResult] = useMutation(
    EDIT_PLACE_MUTATION,
    {
      refetchQueries: [
        {
          query: GET_ALL_PLACES_QUERY,
        },
      ],
      onCompleted: closeModal,
    },
  )

  const onSubmit = async (values: { [x: string]: any }) => {
    const photoBase64 = await fileToBase64(values.photo)
    editPlaceMutation({
      variables: {
        editPlaceInput: {
          ...values,
          placeId: place._id,
          photo: photoBase64,
        },
      },
    })
  }

  const buttonClass = clsx('btn btn-primary', {
    'opacity-30': editPlaceMutationResult.loading,
  })

  return (
    <div>
      <h2 className="title">{"Modifier le point d'intérêt"}</h2>

      <div className="mt-8">
        <div className="text-light">
          <form onSubmit={handleSubmit(onSubmit)}>
            <PlaceForm control={control as any} />

            <div className="flex justify-end mt-6">
              <button
                className={buttonClass}
                disabled={editPlaceMutationResult.loading}
              >
                {editPlaceMutationResult.loading
                  ? 'Enregistrement...'
                  : 'Enregistrer'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default EditPlaceModal
