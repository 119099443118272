import { useQuery } from '@apollo/client'
import { useContext } from 'react'
import { QRCodeCanvas } from 'qrcode.react';
import { useParams } from 'react-router-dom'
import ErrorMessage from '../components/common/ErrorMessage'
import Loading from '../components/common/Loading'
import PlaceOffers from '../components/place/PlaceOffers'
import PlacePhoto from '../components/place/PlacePhoto'
import { LayoutContext } from '../contexts/layout.context'
import Modal from '../enums/modal.enum'
import { GET_PLACE_QUERY } from '../graphql/place.graphql'
import Card from '../components/common/Card';

const PlaceDetails = () => {
  const { placeId } = useParams() as { placeId: string }
  const { openModal } = useContext(LayoutContext)

  const handleEdit = () => openModal(Modal.EDIT_PLACE, { place })

  const getPlaceQuery = useQuery(GET_PLACE_QUERY, {
    variables: {
      getPlaceInput: {
        placeId,
      },
    },
  })

  if (getPlaceQuery.loading) {
    return <Loading />
  }

  if (getPlaceQuery.error) {
    return <ErrorMessage message={getPlaceQuery.error.message} />
  }

  const place = getPlaceQuery.data.getPlace

  return (
    <Card>
      <div className="flex items-end justify-between h-12">
        <h1 className="title">{place.name}</h1>
        <button className="btn btn-primary" onClick={handleEdit}>{"Modifier"}</button>
      </div>

      <div className="mt-8">
        <div className="flex justify-between">
          <PlacePhoto place={place} />
          <div>{`zarlor://places/${place.viewToken}`}</div>
          <QRCodeCanvas value={`zarlor://places/${place.viewToken}`} />
        </div>
        <PlaceOffers place={place} className="mt-8" />
      </div>
    </Card>
  )
}

export default PlaceDetails
