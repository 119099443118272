import clsx from 'clsx'
import IOffer from '../../interfaces/offer.interface'
import IPlace from '../../interfaces/place.interface'
import OfferItem from '../offer/OfferItem'

interface IProps {
  place: IPlace
  className?: string
}

const PlaceOffers = ({ place, className }: IProps) => {
  const cls = clsx("flex flex-col gap-4", className)
  return (
    <div className={cls}>
      <h3 className="card-title">{`Offres à proximité (${place.offers.length})`}</h3>
      <div className="mt-2 flex flex-col gap-4">
        {place.offers.map((offer: IOffer) => (
          <OfferItem offer={{ ...offer, place }} key={offer._id} displayPartner />
        ))}
      </div>
    </div>
  )
}

export default PlaceOffers
