import IOffer from "../../interfaces/offer.interface";

interface IProps {
  offer: IOffer
}

const OfferPhoto = ({ offer }: IProps) => {
  return (
    <div
      className="flex-grow-0 flex-shrink-0 w-40 h-40 rounded-2xl"
      style={{
        backgroundImage: offer.photo ? `url("${offer.photo.url}")` : '',
        backgroundSize: 'cover',
      }}
    />
  );
};

export default OfferPhoto;

