import clsx from 'clsx'
import React from 'react'
import { NavLink } from 'react-router-dom'
import IMenuOption from '../../interfaces/menu-option.interface'

interface IProps {
  menu: IMenuOption
}

const MenuItem = ({ menu }: IProps) => {
  return (
    <li>
      <NavLink
        to={menu.path}
        className={({ isActive }) =>
          clsx(
            'font-semibold',
            isActive
              ? 'text-purple-main underline underline-offset-4'
              : 'text-gray-placeholder hover:text-black',
          )
        }
      >
        {menu.name}
      </NavLink>
    </li>
  )
}

export default MenuItem
