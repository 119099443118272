import CustomMapPicker from './CustomMapPicker';
import { DEFAULT_ZOOM } from '../../constants/map.constant'
import ILocation from '../../interfaces/location.interface'
import InputLabel from './InputLabel'

interface IProps {
  value?: ILocation
  onChange?: (newLocation: ILocation) => void
  label?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
}

const containerStyle = {
  height: `200px`,
}

const PositionInput = ({
  value,
  onChange = () => null,
  label,
}: IProps) => {
  const handleChangeLocation = (lat: number, lng: number) =>
    onChange({ lat, lng })

  return (
    <div className="flex-grow w-full">
      {label && <InputLabel label={label} className="mb-1 ml-2" />}
      <CustomMapPicker
        defaultLocation={value as ILocation}
        zoom={DEFAULT_ZOOM}
        mapTypeId={'roadmap' as any}
        style={containerStyle}
        onChangeLocation={handleChangeLocation}
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}
      />
    </div>
  )
}

export default PositionInput
