const MENU = [
  {
    name: 'Tableau de bord',
    path: '/tableau-de-bord',
  },
  {
    name: "Points d'intérêt",
    path: '/points-dinteret',
  },
  {
    name: 'Circuits',
    path: '/circuits',
  },
  {
    name: 'Partenaires',
    path: '/partenaires',
  },
  {
    name: 'Utilisateurs',
    path: '/utilisateurs',
  },
]

export default MENU
