import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import Card from '../components/common/Card'
import ControlledField from '../components/common/ControlledField'
import ErrorMessage from '../components/common/ErrorMessage'
import Logo from '../components/common/Logo'
import FormFieldType from '../enums/form-field.enum'
import { LOGIN_MUTATION } from '../graphql/auth.graphql'
import { AuthContext } from '../contexts/auth.context'
import IAuthDataType from '../interfaces/auth-data.interface'

const Login = () => {
  const { handleSubmit, control } = useForm()
  const { login } = useContext(AuthContext)

  const [loginMutation, loginMutationResult] = useMutation(LOGIN_MUTATION, {
    onCompleted: (data) => {
      login(data.login as IAuthDataType)
    },
  })

  const onSubmit = (values: { [x: string]: any }) => {
    loginMutation({ variables: { loginInput: values } })
  }

  return (
    <div className="flex justify-center py-40">
      <Card className="flex flex-col justify-center w-full gap-6 md:w-96">
        <div className="flex justify-center">
          <Logo className="w-16 h-16" withoutText />
        </div>
        <h1 className="text-center title">{'Se connecter'}</h1>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
          <div className="flex flex-col w-full gap-4">
            <ControlledField
              label="Email"
              type={FormFieldType.TEXT}
              control={control}
              name="email"
              rules={{ required: 'Ce champs est obligatoire' }}
            />
            <ControlledField
              label="Mot de passe"
              type={FormFieldType.PASSWORD}
              control={control}
              name="password"
              rules={{ required: 'Ce champs est obligatoire' }}
            />
          </div>

          {loginMutationResult.error && (
            <ErrorMessage message="Identifiant ou mot de passe invalide" />
          )}



          <div className="flex justify-center">
            <button className="btn btn-primary">{'Connexion'}</button>
          </div>
        </form>
      </Card>
    </div>
  )
}

export default Login
