import clsx from 'clsx'
import { ReactElement, ChangeEvent } from 'react'
import { FieldError } from 'react-hook-form'
import InputLabel from './InputLabel'

interface IProps {
  placeholder?: string
  value?: string
  className?: string
  onChange?: (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
  ) => void
  onFocus?: () => void
  error?: FieldError
  label?: string
  type?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
}

interface InputProps {
  onFocus?: () => void | undefined
  onChange?: (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
  ) => void
  type?: string
  className?: string
  value?: string
  placeholder?: string
}

const TextInput = ({
  placeholder,
  type = 'text',
  className,
  onChange,
  error,
  onFocus,
  value = '',
  label,
}: IProps): ReactElement => {
  const borderClass = error ? 'border-orange-main' : 'border-gray-border'
  const inputClass = clsx(
    'block px-4 py-3 border outline-none rounded-3xl text-sm flex-shrink-0 w-full',
    borderClass,
    className,
  )

  const allProps: InputProps = {
    onFocus,
    onChange,
    type,
    className: inputClass,
    value,
  }

  if (!label) {
    allProps.placeholder = placeholder
  }

  return (
    <div className="flex-grow w-full">
      {label && <InputLabel label={label} className="mb-1 ml-2" />}
      {type === 'textarea' ? (
        <textarea {...allProps} rows={6} />
      ) : (
        <input {...allProps} />
      )}
    </div>
  )
}

export default TextInput
