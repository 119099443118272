import { useQuery } from '@apollo/client'
import { PencilIcon } from '@heroicons/react/solid'
import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import Card from '../components/common/Card'
import ErrorMessage from '../components/common/ErrorMessage'
import Loading from '../components/common/Loading'
import PartnerOffers from '../components/partner/PartnerOffers'
import { LayoutContext } from '../contexts/layout.context'
import Modal from '../enums/modal.enum'
import { GET_PARTNER_QUERY } from '../graphql/partner.graphql'

const PartnerDetails = () => {
  const { partnerId } = useParams() as { partnerId: string }
  const { openModal } = useContext(LayoutContext)

  const addOfferModal = () => openModal(Modal.ADD_OFFER, { partner })

  const getPartnerQuery = useQuery(GET_PARTNER_QUERY, {
    variables: {
      getPartnerInput: {
        partnerId,
      },
    },
  })

  if (getPartnerQuery.loading) {
    return <Loading />
  }

  if (getPartnerQuery.error) {
    return <ErrorMessage message={getPartnerQuery.error.message} />
  }

  const partner = getPartnerQuery.data.getPartner

  const handleEdit = () => openModal(Modal.EDIT_PARTNER, { partner })

  return (
    <div>
      <div>
        <div className="flex items-end justify-between h-12">

          <h1>{`${partner.name}`}</h1>



          <div className="flex gap-2">

            <button
              className="flex btn btn-primary-outline"
              onClick={handleEdit}
            >
              <PencilIcon className="w-6 h-6" />
              <span>{' Modifier'}</span>
            </button>
            <button className="btn btn-primary" onClick={addOfferModal}>
              {'Ajouter une offre'}
            </button>
          </div>
        </div>

        <Card className="mt-8">
          <div dangerouslySetInnerHTML={{ __html: partner.description }}></div>
        </Card>

        <PartnerOffers partner={partner} className="mt-8" />
      </div>
    </div >
  )
}

export default PartnerDetails
