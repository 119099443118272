import { useContext, ReactElement } from 'react'
import { Dialog } from '@headlessui/react'
import clsx from 'clsx'
import { XIcon } from '@heroicons/react/solid'
import { LayoutContext } from '../../contexts/layout.context'
import AddInterestPointModal from '../modals/AddPlaceModal'
import Modal from '../../enums/modal.enum'
import AddCircuitModal from '../modals/AddCircuitModal'
import EditPlaceModal from '../modals/EditPlaceModal'
import IPlace from '../../interfaces/place.interface'
import EditCircuitModal from '../modals/EditCircuitModal'
import ICircuit from '../../interfaces/circuit.interface'
import IPartner from '../../interfaces/partner.interface'
import EditPartnerModal from '../modals/EditPartnerModal'
import AddPartnerModal from '../modals/AddPartnerModal'
import AddOfferModal from '../modals/AddOfferModal'
import EditOfferModal from '../modals/EditOfferModal'
import IOffer from '../../interfaces/offer.interface'

const ModalContainer = (): ReactElement => {
  const { modal, closeModal } = useContext(LayoutContext)

  const renderModal = (): ReactElement | null => {
    if (modal) {
      switch (modal.name) {
        case Modal.ADD_PLACE:
          return <AddInterestPointModal />

        case Modal.ADD_CIRCUIT:
          return <AddCircuitModal />

        case Modal.EDIT_PLACE:
          return <EditPlaceModal {...(modal.data as { place: IPlace })} />

        case Modal.EDIT_CIRCUIT:
          return <EditCircuitModal {...(modal.data as { circuit: ICircuit })} />

        case Modal.ADD_PARTNER:
          return <AddPartnerModal />

        case Modal.EDIT_PARTNER:
          return <EditPartnerModal {...(modal.data as { partner: IPartner })} />

        case Modal.ADD_OFFER:
          return <AddOfferModal {...(modal.data as { partner: IPartner })} />

        case Modal.EDIT_OFFER:
          return <EditOfferModal {...(modal.data as { offer: IOffer })} />

        default:
          return null
      }
    }
    return null
  }

  const modalContainer = clsx(
    'fixed z-50 inset-0 flex flex-col items-center justify-center overflow-auto',
  )

  const modalOverlay =
    'fixed inset-0 bg-black-title opacity-30 h-screen w-screen bg-black'

  return (
    <Dialog
      open={!!modal && !!modal.name}
      onClose={() => null}
      className={modalContainer}
    >
      <div
        className={`bg-white w-full relative mx-6 p-10 rounded-xl z-40 md:w-[504px] overflow-auto max-h-screen`}
      >
        <div className="absolute right-3 top-3">
          <button data-testid="close-modal-btn" onClick={closeModal}>
            <XIcon className="w-6 h-6 text-black" />
          </button>
        </div>
        {renderModal()}
      </div>
      <Dialog.Overlay className={modalOverlay} />
    </Dialog>
  )
}

export default ModalContainer
