import { ReactNode } from 'react'
import Container from '../common/Container'
import Menu from './Navbar'

interface IProps {
  children: ReactNode
}

const AuthLayout = ({ children }: IProps) => {
  return (
    <div className="flex flex-col h-screen">
      <Menu />

      <div className="flex-grow py-20 bg-gray-main main-container">
        <Container>{children}</Container>
      </div>
    </div>
  )
}

export default AuthLayout
