import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import Card from '../components/common/Card'
import ErrorMessage from '../components/common/ErrorMessage'
import Loading from '../components/common/Loading'
import { GET_CIRCUIT_QUERY } from '../graphql/circuit.graphql'

import AddPlaceToCircuitForm from '../components/circuit/AddPlaceToCircuitForm'
import Modal from '../enums/modal.enum'
import { useContext } from 'react'
import { LayoutContext } from '../contexts/layout.context'
import CircuitSteps from '../components/circuit/CircuitSteps'

const CircuitDetails = () => {
  const { openModal } = useContext(LayoutContext)
  const { circuitId } = useParams() as { circuitId: string }

  const getCircuitQuery = useQuery(GET_CIRCUIT_QUERY, {
    variables: {
      getCircuitInput: {
        circuitId,
      },
    },
  })

  if (getCircuitQuery.loading) {
    return <Loading />
  }

  if (getCircuitQuery.error) {
    return <ErrorMessage message={getCircuitQuery.error.message} />
  }

  const circuit = getCircuitQuery.data.getCircuit
  const handleEdit = () => openModal(Modal.EDIT_CIRCUIT, { circuit })

  return (
    <div>
      <div className="flex items-end justify-between h-12">
        <h1>{circuit.name}</h1>
        <button className="btn btn-primary" onClick={handleEdit}>{"Modifier"}</button>
      </div>

      <div className="flex flex-col gap-6 mt-8">
        <Card className="flex flex-col gap-4">
          <div className="flex gap-6">
            <div
              className="flex-grow-0 flex-shrink-0 w-40 h-40 rounded-2xl"
              style={{
                backgroundImage: circuit.places.length
                  ? `url("${circuit.places[0].photo.url}")`
                  : '',
                backgroundSize: 'cover',
              }}
            />

            <div>
              <CircuitSteps className="mt-2" circuit={circuit} />

              <div>
                <div className="mt-6">{circuit.description}</div>
              </div>

            </div>
          </div>
        </Card>

        <AddPlaceToCircuitForm circuit={circuit} className="mt-8" />
      </div>
    </div>
  )
}

export default CircuitDetails
