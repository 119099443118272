import React, { ReactNode } from 'react'

interface IProps {
  children: ReactNode
}

const NonAuthLayout = ({ children }: IProps) => {
  return <div className="flex flex-col h-screen bg-gray-main">{children}</div>
}

export default NonAuthLayout
