import ControlledField from '../common/ControlledField'
import FormFieldType from '../../enums/form-field.enum'
import { Control, FieldValues } from 'react-hook-form'

interface IProps {
  control: Control<FieldValues, object> | undefined
}

const CircuitForm = ({ control }: IProps) => {
  return (
    <div className="flex flex-col gap-2">
      <ControlledField
        name="name"
        label="Nom"
        control={control}
        rules={{ required: 'Ce champ est requis' }}
        type={FormFieldType.TEXT}
      />
      <ControlledField
        name="category"
        label="Catégorie"
        control={control}
        rules={{ required: 'Ce champ est requis' }}
        type={FormFieldType.CATEGORY}
      />
      <ControlledField
        name="sector"
        label="Secteur"
        control={control}
        rules={{ required: 'Ce champ est requis' }}
        type={FormFieldType.TEXT}
      />
      <ControlledField
        type={FormFieldType.LONG_TEXT}
        name="description"
        label="Description"
        control={control}
        rules={{ required: 'Ce champ est requis' }}
      />
    </div>
  )
}

export default CircuitForm
