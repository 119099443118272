import { useMutation } from '@apollo/client'
import clsx from 'clsx'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { DEFAULT_POSITION } from '../../constants/map.constant'
import { LayoutContext } from '../../contexts/layout.context'
import {
  ADD_PLACE_MUTATION,
  GET_ALL_PLACES_QUERY,
} from '../../graphql/place.graphql'
import { fileToBase64 } from '../../lib/helper'
import PlaceForm from '../place/PlaceForm'

const AddPlaceModal = () => {
  const { handleSubmit, control } = useForm({
    defaultValues: {
      location: DEFAULT_POSITION,
      name: '',
      description: '',
    },
  })
  const { closeModal } = useContext(LayoutContext)
  const [addPlaceMutation, addPlaceMutationResult] = useMutation(
    ADD_PLACE_MUTATION,
    {
      refetchQueries: [
        {
          query: GET_ALL_PLACES_QUERY,
        },
      ],
      onCompleted: closeModal,
    },
  )

  const onSubmit = async (values: { [x: string]: any }) => {
    const photoBase64 = await fileToBase64(values.photo)
    addPlaceMutation({
      variables: {
        addPlaceInput: { ...values, photo: photoBase64 },
      },
    })
  }

  const buttonClass = clsx('btn btn-primary', {
    'opacity-30': addPlaceMutationResult.loading,
  })

  return (
    <div>
      <h2 className="title">{"Ajout d'un point d'intérêt"}</h2>

      <div className="mt-8">
        <div className="text-light">
          <form onSubmit={handleSubmit(onSubmit)}>
            <PlaceForm control={control as any} />

            <div className="flex justify-end mt-6">
              <button
                className={buttonClass}
                disabled={addPlaceMutationResult.loading}
              >
                {addPlaceMutationResult.loading
                  ? 'Enregistrement...'
                  : 'Enregistrer'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddPlaceModal
