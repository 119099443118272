import { gql } from '@apollo/client'

export const GET_ALL_USERS_QUERY = gql`
  query getAllUsers {
    getAllUsers {
      _id
      firstName
      lastName
      role
      identity
      acquisitionChannel
      preferences
      createdAt
    }
  }
`
