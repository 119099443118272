import { useMutation } from '@apollo/client'
import clsx from 'clsx'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { LayoutContext } from '../../contexts/layout.context'
import {
  EDIT_PARTNER_MUTATION,
  GET_ALL_PARTNERS_QUERY,
} from '../../graphql/partner.graphql'
import IPartner from '../../interfaces/partner.interface'

import PartnerForm from '../partner/PartnerForm'

interface IProps {
  partner: IPartner
}

const EditPartnerModal = ({ partner }: IProps) => {
  const { handleSubmit, control } = useForm({
    defaultValues: {
      name: partner.name,
      description: partner.description,
    },
  })
  const { closeModal } = useContext(LayoutContext)
  const [editPartnerMutation, editPartnerMutationResult] = useMutation(
    EDIT_PARTNER_MUTATION,
    {
      refetchQueries: [
        {
          query: GET_ALL_PARTNERS_QUERY,
        },
      ],
      onCompleted: closeModal,
    },
  )

  const onSubmit = async (values: { [x: string]: any }) => {
    editPartnerMutation({
      variables: {
        editPartnerInput: {
          ...values,
          partnerId: partner._id,
        },
      },
    })
  }

  const buttonClass = clsx('btn btn-primary', {
    'opacity-30': editPartnerMutationResult.loading,
  })

  return (
    <div>
      <h2 className="title">{'Modifier le partenaire'}</h2>

      <div className="mt-8">
        <div className="text-light">
          <form onSubmit={handleSubmit(onSubmit)}>
            <PartnerForm control={control as any} />

            <div className="flex justify-end mt-6">
              <button
                className={buttonClass}
                disabled={editPartnerMutationResult.loading}
              >
                {editPartnerMutationResult.loading
                  ? 'Enregistrement...'
                  : 'Enregistrer'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default EditPartnerModal
