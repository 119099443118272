import clsx from 'clsx'
import { ReactElement } from 'react'

interface IProps {
  label: string
  className?: string
}

const InputLabel = ({ label, className = '' }: IProps): ReactElement => {
  const labelClass = clsx('italic font-opensans', className)
  return <div className={labelClass}>{label}</div>
}

export default InputLabel
