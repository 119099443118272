import MENU from '../../constants/menu.constant'
import Container from '../common/Container'
import Logo from '../common/Logo'
import MenuItem from './MenuItem'
import { LogoutIcon } from '@heroicons/react/solid'
import { useContext } from 'react'
import { AuthContext } from '../../contexts/auth.context'

const Navbar = () => {
  const { logout } = useContext(AuthContext)
  return (
    <div className="h-[72px] bg-white px-4 flex items-center">
      <div className="w-[100px] flex justify-start">
        <Logo className="w-24 h-12" />
      </div>
      <Container>
        <nav className="px-12">
          <ul className="justify-between items-center flex h-[72px] m-auto center">
            {MENU.map((menu) => (
              <MenuItem menu={menu} key={menu.path} />
            ))}
          </ul>
        </nav>
      </Container>
      <div className="w-[100px] flex justify-end">
        <LogoutIcon
          className="w-6 h-6 cursor-pointer text-gray-placeholder hover:text-black"
          onClick={logout}
        />
      </div>
    </div>
  )
}

export default Navbar
