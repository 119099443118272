import React from 'react'
import IOffer from '../../interfaces/offer.interface'
import IPartner from '../../interfaces/partner.interface'
import Card from '../common/Card'
import OfferItem from '../offer/OfferItem'

interface IProps {
  partner: IPartner
  className?: string
}

const PartnerOffers = ({ partner, className = '' }: IProps) => {
  return (
    <div className={className}>
      <Card className="flex flex-col gap-4">
        <h3 className="card-title">{`Offres (${partner.offers.length})`}</h3>
        <div className="mt-2 flex flex-col gap-4">
          {partner.offers.map((offer: IOffer) => (
            <OfferItem offer={{ ...offer, partner }} key={offer._id} displayPlace />
          ))}
        </div>
      </Card>
    </div>
  )
}

export default PartnerOffers
