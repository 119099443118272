import { ReactElement } from 'react'
import { FieldError } from 'react-hook-form'
import CATEGORY_OPTIONS from '../../constants/category-options.constant'
import FormFieldType from '../../enums/form-field.enum'
import ImageInput from './ImageInput'
import MultiTextInput from './MultiTextInput'
import PositionInput from './PositionInput'
import RichTextInput from './RichTextInput'
import Select from './Select'
import TextInput from './TextInput'

interface IProps {
  type: FormFieldType
  error?: FieldError | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
}

const FormField = ({ type, ...otherProps }: IProps): ReactElement | null => {
  switch (type) {
    case FormFieldType.TEXT:
      return <TextInput {...otherProps} type="text" />

    case FormFieldType.LONG_TEXT:
      return <TextInput {...otherProps} type="textarea" />

    case FormFieldType.PASSWORD:
      return <TextInput {...otherProps} type="password" />

    case FormFieldType.MULTI_TEXT:
      return <MultiTextInput {...otherProps} />

    case FormFieldType.CATEGORY: {
      return <Select {...otherProps} options={CATEGORY_OPTIONS} />
    }

    case FormFieldType.IMAGE:
      return <ImageInput {...otherProps} />

    case FormFieldType.POSITION:
      return <PositionInput {...otherProps} />

    case FormFieldType.RICH_TEXT:
      return <RichTextInput {...otherProps} />

    default:
      return null
  }
}

export default FormField
