import { PencilIcon } from '@heroicons/react/solid'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { LayoutContext } from '../../contexts/layout.context'
import Modal from '../../enums/modal.enum'
import IOffer from '../../interfaces/offer.interface'
import InnerCard from '../common/InnerCard'
import OfferPhoto from './OfferPhoto'

interface IProps {
  offer: IOffer
  displayPartner?: boolean
  displayPlace?: boolean
}

const OfferItem = ({ offer, displayPartner = false, displayPlace = false }: IProps) => {
  const { openModal } = useContext(LayoutContext)
  const handleEdit = () => openModal(Modal.EDIT_OFFER, {
    offer
  })

  return (
    <InnerCard>
      <div className="flex gap-6 w-full">
        <OfferPhoto offer={offer} />
        <div className="flex-grow">
          <h3 className="card-title">{offer.name}</h3>

          <div dangerouslySetInnerHTML={{ __html: offer.description }}></div>

          <div className="mt-6">
            {displayPlace && (<div>
              <span>{`Point d'interêt : `}</span>
              <Link
                to={`/points-dinteret/${offer.place._id}`}
                className="underline text-purple-main cursor-pointer"
              >
                {offer.place.name}
              </Link>
            </div>)}
            {displayPartner && (<div>
              <span>{`Partenaire : `}</span>
              <Link
                to={`/partenaires/${offer.partner._id}`}
                className="underline text-purple-main cursor-pointer"
              >
                {offer.partner.name}
              </Link>
            </div>)}

          </div>

        </div>
        <div className="self-start">
          <button
            className="flex btn btn-primary-outline"
            onClick={handleEdit}
          >
            <PencilIcon className="w-6 h-6" />
            <span>{' Modifier'}</span>
          </button>
        </div>
      </div>
    </InnerCard>
  )
}

export default OfferItem
