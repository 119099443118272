import { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import AuthLayout from '../components/layouts/AuthLayout'
import NonAuthLayout from '../components/layouts/NonAuthLayout'
import { AuthContext } from '../contexts/auth.context'

const PrivateRoute = ({ publicRoute = false }) => {
  const { user } = useContext(AuthContext)
  const isAuthorized = publicRoute ? !user : !!user

  const returnRoute = publicRoute ? '/tableau-de-bord' : '/login'
  return isAuthorized ? (
    <>
      {publicRoute ? (
        <NonAuthLayout>
          <Outlet />
        </NonAuthLayout>
      ) : (
        <AuthLayout>
          <Outlet />
        </AuthLayout>
      )}
    </>
  ) : (
    <Navigate to={returnRoute} />
  )
}

export default PrivateRoute
