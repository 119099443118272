import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import Cookies from 'js-cookie'

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_BACKEND_URL}/graphql`,
  fetch,
})

const authLink = setContext(async (_, { headers }) => {
  const userSaved = Cookies.get('auth')

  if (userSaved) {
    const user = JSON.parse(userSaved)
    return {
      headers: {
        ...headers,
        authorization: user ? `Bearer ${user.accessToken}` : '',
      },
    }
  }

  return {
    headers,
  }
})

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  ssrMode: typeof window === 'undefined',
  cache: new InMemoryCache(),
})

export default apolloClient
