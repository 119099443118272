import React from 'react';
import ICircuit from '../../interfaces/circuit.interface';

interface IProps {
  circuit: ICircuit;
  className: string
}

const CircuitSteps = ({ circuit, className }: IProps) => {
  return (
    <div className={className}>
      <span className="opacity-60 text-base">{`${circuit.places.length
        } ${circuit.places.length > 1
          ? `étapes`
          : `étape`
        } • ${circuit.sector}`}</span>
    </div>
  );
};

export default CircuitSteps;
