import { useMutation } from '@apollo/client'
import clsx from 'clsx'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { LayoutContext } from '../../contexts/layout.context'
import { ADD_OFFER_MUTATION } from '../../graphql/offer.graphql'
import { GET_PARTNER_QUERY } from '../../graphql/partner.graphql'
import IPartner from '../../interfaces/partner.interface'
import { fileToBase64 } from '../../lib/helper'
import OfferForm from '../offer/OfferForm'

interface IProps {
  partner: IPartner
}

const AddOfferModal = ({ partner }: IProps) => {
  const { handleSubmit, control } = useForm()
  const { closeModal } = useContext(LayoutContext)
  const [addOfferMutation, addOfferMutationResult] = useMutation(
    ADD_OFFER_MUTATION,
    {
      refetchQueries: [
        {
          query: GET_PARTNER_QUERY,
          variables: {
            getPartnerInput: {
              partnerId: partner._id,
            }
          },
        },
      ],
      onCompleted: closeModal,
    },
  )

  const onSubmit = async (values: { [x: string]: any }) => {
    addOfferMutation({
      variables: {
        addOfferInput: {
          ...values,
          partner: partner._id,
          place: values.place._id,
          photo: await fileToBase64(values.photo),
        },
      },
    })
  }

  const buttonClass = clsx('btn btn-primary', {
    'opacity-30': addOfferMutationResult.loading,
  })

  return (
    <div>
      <h2 className="title">{"Ajout d'une offre"}</h2>

      <div className="mt-8">
        <div className="text-light">
          <form onSubmit={handleSubmit(onSubmit)}>
            <OfferForm control={control as any} withPlace />

            <div className="flex justify-end mt-6">
              <button
                className={buttonClass}
                disabled={addOfferMutationResult.loading}
              >
                {addOfferMutationResult.loading
                  ? 'Enregistrement...'
                  : 'Enregistrer'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddOfferModal
