import { gql } from '@apollo/client'

export const PARTNER_FRAGMENT = gql`
  fragment partnerFragment on Partner {
    _id
    name
    description
    offers {
      _id
      name
      description
      photo {
        url
      }
      place {
        _id
        name
      }
    }
  }
`

export const GET_ALL_PARTNERS_QUERY = gql`
  query getAllPartners {
    getAllPartners {
      ...partnerFragment
    }
  }
  ${PARTNER_FRAGMENT}
`

export const ADD_PARTNER_MUTATION = gql`
  mutation addPartner($addPartnerInput: AddPartnerInput!) {
    addPartner(addPartnerInput: $addPartnerInput) {
      _id
    }
  }
`

export const EDIT_PARTNER_MUTATION = gql`
  mutation editPartner($editPartnerInput: EditPartnerInput!) {
    editPartner(editPartnerInput: $editPartnerInput) {
      _id
    }
  }
`

export const GET_PARTNER_QUERY = gql`
  query getPartner($getPartnerInput: GetPartnerInput!) {
    getPartner(getPartnerInput: $getPartnerInput) {
      ...partnerFragment
    }
  }
  ${PARTNER_FRAGMENT}
`
