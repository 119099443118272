import { useNavigate } from 'react-router-dom'
import ICircuit from '../../interfaces/circuit.interface'
import InnerCard from '../common/InnerCard'
import CircuitSteps from './CircuitSteps'

interface IProps {
  circuit: ICircuit
}

const CircuitItem = ({ circuit }: IProps) => {
  const navigation = useNavigate()
  const handleClick = () => navigation(`/circuits/${circuit._id}`)

  const firstPlace = circuit.places[0];

  return (
    <InnerCard>
      <div className="flex gap-6">
        <div
          className="flex-grow-0 flex-shrink-0 w-40 h-40 rounded-2xl"
          style={{
            backgroundImage: firstPlace
              ? `url("${firstPlace.photo.url}")`
              : '',
            backgroundSize: 'cover',
          }}
        />

        <div>
          <h3 className="title">{circuit.name}</h3>

          <CircuitSteps className="mt-2" circuit={circuit} />

          <div className="mt-6">{circuit.description}</div>
        </div>
      </div>

      <div className="flex justify-end gap-4 mt-4">
        <button className="btn btn-primary-outline" onClick={handleClick}>
          {'Voir les détails'}
        </button>
      </div>
    </InnerCard>
  )
}

export default CircuitItem
