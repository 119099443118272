import AcquisitionChannel from '../enums/acquisition-channel.enum'
import CategoryPreference from '../enums/category-preference.enum'
import Identity from '../enums/identity.enum'
import Role from '../enums/role.enum'

export const fileToBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

export const displayUserRole = (role: Role) => {
  if (role === Role.ADMIN) return 'Administrateur'

  return 'Utilisateur'
}

export const renderCategoryPreference = (categoryPreference: CategoryPreference) => {
  switch (categoryPreference) {
    case CategoryPreference.BEACH:
      return '🏖️ Plage'

    case CategoryPreference.MOUNTAIN:
      return '⛰️ Montagne'

    default:
      return ''
  }
}

export const renderUserIdentity = (userIdentity: Identity) => {
  switch (userIdentity) {
    case Identity.PASSING:
      return 'Passant'

    case Identity.RESIDENT:
      return 'Résident'

    case Identity.TOURIST:
      return 'Touriste'

    default:
      return ''
  }
}

export const renderAcquisitionChannel = (acquisitionChannel: AcquisitionChannel) => {
  switch (acquisitionChannel) {
    case AcquisitionChannel.EVENT:
      return 'Évenement'

    case AcquisitionChannel.FRIENDS:
      return 'Amis'

    case AcquisitionChannel.INTERNET:
      return 'Internet'

    case AcquisitionChannel.OTHER:
      return 'Autre'

    case AcquisitionChannel.SOCIAL_NETWORK:
      return 'Réseaux sociaux'

    case AcquisitionChannel.WORD_OF_MOUTH:
      return 'Bouche à oreille'

    default:
      return ''
  }
}
