import { gql } from '@apollo/client'

export const OFFER_FRAGMENT = gql`
  fragment offerFragment on Offer {
    _id
    name
    description
    photo {
      url
    }
    place {
      _id
      name
    }
  }
`

export const ADD_OFFER_MUTATION = gql`
  mutation addOffer($addOfferInput: AddOfferInput!) {
    addOffer(addOfferInput: $addOfferInput) {
      _id
    }
  }
`

export const EDIT_OFFER_MUTATION = gql`
  mutation editOffer($editOfferInput: EditOfferInput!) {
    editOffer(editOfferInput: $editOfferInput) {
      _id
    }
  }
`

export const REMOVE_OFFER_MUTATION = gql`
  mutation removeOffer($removeOfferInput: RemoveOfferInput!) {
    removeOffer(removeOfferInput: $removeOfferInput) {
      _id
    }
  }
`

