import { useState, createContext, ReactElement } from 'react'
import cookie from 'js-cookie'
import IAuthDataType from '../interfaces/auth-data.interface'

type ContextProps = {
  login: (user: IAuthDataType) => void
  logout: () => void
  updateAccessToken: (accessToken: string) => void
  isAuthenticated: boolean
  user: IAuthDataType | null
}

export const AuthContext = createContext<ContextProps>({
  login: () => null,
  logout: () => null,
  updateAccessToken: () => null,
  isAuthenticated: false,
  user: null,
})

interface IProps {
  children: React.ReactNode
}

const AuthContextProvider = ({ children }: IProps): ReactElement => {
  const userSaved = cookie.get('auth')
  const defaultUserValue = userSaved ? JSON.parse(userSaved) : null
  const [user, setUser] = useState<IAuthDataType | null>(defaultUserValue)

  const saveAuthData = (authData: IAuthDataType | null) => {
    return authData
      ? cookie.set('auth', JSON.stringify(authData))
      : cookie.remove('auth')
  }

  const saveUser = (_userData: IAuthDataType | null) => {
    saveAuthData(_userData)
    setUser(_userData)
  }

  const onLogout = () => {
    saveUser(null)
    // const apolloClient = initializeApollo()
    // apolloClient.cache.reset()
  }

  const onUpdateAccessToken = (accessToken: string) =>
    saveUser({ ...user, accessToken })

  return (
    <AuthContext.Provider
      value={{
        login: saveUser,
        updateAccessToken: onUpdateAccessToken,
        logout: onLogout,
        isAuthenticated: !!user,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider
