import { gql } from '@apollo/client'

export const PLACE_FRAGMENT = gql`
  fragment placeFragment on Place {
    _id
    name
    description
    tags
    viewToken
    photo {
      url
    }
    location {
      coordinates
    }
    offers {
      _id
      name
      partner {
        _id
        name
      }
    }
  }
`


export const GET_PLACE_QUERY = gql`
  query getPlace($getPlaceInput: GetPlaceInput!) {
    getPlace(getPlaceInput: $getPlaceInput) {
      ...placeFragment
    }
  }
  ${PLACE_FRAGMENT}
`

export const GET_ALL_PLACES_QUERY = gql`
  query getAllPlaces {
    getAllPlaces {
      ...placeFragment
    }
  }
  ${PLACE_FRAGMENT}
`

export const ADD_PLACE_MUTATION = gql`
  mutation addPlace($addPlaceInput: AddPlaceInput!) {
    addPlace(addPlaceInput: $addPlaceInput) {
      _id
    }
  }
`

export const EDIT_PLACE_MUTATION = gql`
  mutation editPlace($editPlaceInput: EditPlaceInput!) {
    editPlace(editPlaceInput: $editPlaceInput) {
      _id
    }
  }
`
