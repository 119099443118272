import ControlledField from '../common/ControlledField'
import FormFieldType from '../../enums/form-field.enum'
import { Control, Controller, FieldValues } from 'react-hook-form'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { Fragment, useState } from 'react'
import IPlace from '../../interfaces/place.interface'
import Loading from '../common/Loading'
import ErrorMessage from '../common/ErrorMessage'
import { GET_ALL_PLACES_QUERY } from '../../graphql/place.graphql'
import { useQuery } from '@apollo/client'

interface IProps {
  control: Control<FieldValues, object> | undefined,
  withPartner?: boolean
  withPlace?: boolean
}

const OfferForm = ({ control, withPartner = false, withPlace = false }: IProps) => {
  const [query, setQuery] = useState('')

  const getAllPlacesQuery = useQuery(GET_ALL_PLACES_QUERY)

  if (getAllPlacesQuery.loading) {
    return <Loading />
  }

  if (getAllPlacesQuery.error) {
    return <ErrorMessage message={getAllPlacesQuery.error.message} />
  }

  const places = getAllPlacesQuery.data.getAllPlaces

  const filteredPlaces =
    query === ''
      ? places
      : places.filter((place: IPlace) =>
        place.name.toLowerCase().includes(query.toLowerCase()),
      )

  return (
    <div className="flex flex-col gap-2">
      <ControlledField
        name="name"
        label="Nom"
        control={control}
        rules={{ required: 'Ce champ est requis' }}
        type={FormFieldType.TEXT}
      />

      <ControlledField
        name="description"
        label="Description"
        control={control}
        rules={{ required: 'Ce champ est requis' }}
        type={FormFieldType.RICH_TEXT}
      />

      {withPlace && <Controller
        rules={{ required: 'Ce champs est obligatoire' }}
        control={control}
        name={"place"}
        render={({
          field: { onChange, value, onBlur },
          fieldState: { error },
        }) => {
          return (
            <div className="flex gap-2 mt-4">
              <div className="w-full">
                <div className="italic font-opensans mb-1 ml-2">{"Point d'intérêt"}</div>
                <Combobox value={null} onChange={onChange}>
                  <div className="relative mt-1">
                    <div className="relative w-full overflow-hidden text-left bg-white border border-gray-main cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm rounded-3xl">
                      <Combobox.Input
                        className="w-full px-4 py-3 border outline-none rounded-3xl text-sm flex-shrink-0 text-gray-900 border-none focus:ring-0"
                        displayValue={(place: IPlace) =>
                          value ? value.name : ''
                        }
                        value={"Choisir le point d'intérêt"}
                        onChange={(event) => setQuery(event.target.value)}
                      />
                      <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                        <SelectorIcon
                          className="w-5 h-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </Combobox.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      afterLeave={() => setQuery('')}
                    >
                      <Combobox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50">
                        {filteredPlaces.length === 0 && query !== '' ? (
                          <div className="relative px-4 py-2 text-gray-700 cursor-default select-none">
                            {'Rien trouvé.'}
                          </div>
                        ) : (
                          filteredPlaces.map((place: IPlace) => (
                            <Combobox.Option
                              key={place._id}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${active
                                  ? 'bg-teal-600 text-white'
                                  : 'text-gray-900'
                                }`
                              }
                              value={place}
                            >
                              {({ selected, active }) => (
                                <>
                                  <span
                                    className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                      }`}
                                  >
                                    {place ? place.name : ''}
                                  </span>
                                  {selected ? (
                                    <span
                                      className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                        }`}
                                    >
                                      <CheckIcon
                                        className="w-5 h-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Combobox.Option>
                          ))
                        )}
                      </Combobox.Options>
                    </Transition>
                  </div>
                </Combobox>
              </div>
            </div>
          )
        }} />}

      {
        withPartner && (
          <Controller
            rules={{ required: 'Ce champs est obligatoire' }}
            control={control}
            name={"partner"}
            render={({
              field: { onChange, value, onBlur },
              fieldState: { error },
            }) => {
              return (
                <div className="flex gap-2 mt-4">
                  <div className="w-full">
                    <Combobox value={null} onChange={onChange}>
                      <div className="relative mt-1">
                        <div className="relative w-full overflow-hidden text-left bg-white border border-gray-main cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm rounded-3xl">
                          <Combobox.Input
                            className="w-full px-4 py-3 border outline-none rounded-3xl text-sm flex-shrink-0 text-gray-900 border-none focus:ring-0"
                            displayValue={(place: IPlace) =>
                              value ? value.name : ''
                            }
                            value={"Choisir le point d'intérêt"}
                            onChange={(event) => setQuery(event.target.value)}
                          />
                          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                            <SelectorIcon
                              className="w-5 h-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </Combobox.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                          afterLeave={() => setQuery('')}
                        >
                          <Combobox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50">
                            {filteredPlaces.length === 0 && query !== '' ? (
                              <div className="relative px-4 py-2 text-gray-700 cursor-default select-none">
                                {'Rien trouvé.'}
                              </div>
                            ) : (
                              filteredPlaces.map((place: IPlace) => (
                                <Combobox.Option
                                  key={place._id}
                                  className={({ active }) =>
                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active
                                      ? 'bg-teal-600 text-white'
                                      : 'text-gray-900'
                                    }`
                                  }
                                  value={place}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <span
                                        className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                          }`}
                                      >
                                        {place ? place.name : ''}
                                      </span>
                                      {selected ? (
                                        <span
                                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                            }`}
                                        >
                                          <CheckIcon
                                            className="w-5 h-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Combobox.Option>
                              ))
                            )}
                          </Combobox.Options>
                        </Transition>
                      </div>
                    </Combobox>
                  </div>
                </div>
              )
            }} />
        )
      }

      <ControlledField
        name="photo"
        label="Photo"
        control={control}
        rules={{ required: 'Ce champ est requis' }}
        type={FormFieldType.IMAGE}
      />
    </div>
  )
}

export default OfferForm
