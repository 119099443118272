import clsx from 'clsx'
import React, { ReactNode } from 'react'

interface IProps {
  children: ReactNode
  className?: string
}

const Card = ({ children, className = '' }: IProps) => {
  return (
    <div
      className={clsx(
        'p-8 font-light bg-white rounded-lg shadow-md',
        className,
      )}
    >
      {children}
    </div>
  )
}

export default Card
