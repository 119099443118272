import { useNavigate } from 'react-router-dom'
import IPartner from '../../interfaces/partner.interface'
import InnerCard from '../common/InnerCard'

interface IProps {
  partner: IPartner
}

const PartnerItem = ({ partner }: IProps) => {
  const navigation = useNavigate()
  const handleClick = () => navigation(`/partenaires/${partner._id}`)
  return (
    <InnerCard>
      <div>
        <div className="flex gap-2 flex-col">
          <h3 className="card-title">{partner.name}</h3>
          <div>
            <div className="badge text-purple-main bg-purple-light">{`${partner.offers.length
              } ${partner.offers.length > 1 ? 'offres' : 'offre'}`}</div>
          </div>
        </div>

        <div className="mt-6">
          <div className="flex justify-end gap-4 mt-4">
            <button className="btn btn-primary-outline" onClick={handleClick}>
              {'Voir les détails'}
            </button>
          </div>
        </div>
      </div>
    </InnerCard>
  )
}

export default PartnerItem
