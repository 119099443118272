import { PlusIcon, XCircleIcon } from '@heroicons/react/solid'
import clsx from 'clsx'
import { ChangeEvent, ReactElement, useState } from 'react'
import { FieldError } from 'react-hook-form'
import InputLabel from './InputLabel'

interface IProps {
  placeholder?: string
  value?: string[]
  className?: string
  onChange?: (
    value: string[],
  ) => void
  onFocus?: () => void
  error?: FieldError
  label?: string
  type?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
}

interface InputProps {
  onFocus?: () => void | undefined
  onChange?: (
    value: string[],
  ) => void
  type?: string
  className?: string
  value?: string[]
  placeholder?: string
}

const MultiTextInput = ({
  placeholder,
  className,
  onChange,
  error,
  onFocus,
  value = [],
  label,
}: IProps): ReactElement => {
  const [currentValue, setCurrentValue] = useState<string>('')
  const borderClass = error ? 'border-orange-main' : 'border-gray-border'
  const inputClass = clsx(
    'block px-4 py-3 border outline-none rounded-3xl text-sm flex-shrink-0 w-full',
    borderClass,
    className,
  )

  const allProps: InputProps = {
    onFocus,
    onChange,
    className: inputClass,
    value,
  }

  if (!label) {
    allProps.placeholder = placeholder
  }

  const addTerm = () => {
    if (!onChange || !currentValue) return
    const newValue = [...value, currentValue]
    onChange(newValue)
    setCurrentValue('')
  }

  const onRemove = (term: string) => {
    if (!onChange || !term) return
    const newValue = value.filter(v => v !== term)
    onChange(newValue)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => setCurrentValue(e.currentTarget.value)

  return (
    <div className="flex-grow w-full">
      {label && <InputLabel label={label} className="mb-1 ml-2" />}

      <div className=" border outline-none rounded-3xl text-sm flex-shrink-0 w-60 flex">
        <input type="text"
          placeholder={placeholder}
          className="px-4 py-3 rounded-l-3xl"
          value={currentValue}
          onChange={handleChange}
        />
        <button className="py-4 px-6 bg-white border border-gray-main rounded-r-3xl" onClick={addTerm} type={"button"}>
          <PlusIcon className="text-purple-main w-6 h-6" />
        </button>

      </div>
      <div className="mt-2 flex gap-2 flex-wrap items-center">
        {(value || []).map((v, index) => (<div key={index} className="px-3 py-2 border border-gray-main rounded-3xl flex gap-2">
          <div>
            {v}
          </div>
          <div>
            <XCircleIcon className="text-gray-main w-4 h-4 cursor-pointer" onClick={() => onRemove(v)} />
          </div>
        </div>))}
      </div>
    </div>
  )
}

export default MultiTextInput
