import { createContext, useState, ReactElement } from 'react'
import Modal from '../enums/modal.enum'
import ModalType from '../interfaces/modal-type.interface'

interface IProps {
  children: React.ReactNode
}

type ContextProps = {
  modal: ModalType | null
  openModal: (name: Modal, data?: unknown) => void
  closeModal: () => void
}

const defaultValue = {
  modal: null,
  openModal: () => null,
  closeModal: () => null,
}

export const LayoutContext = createContext<ContextProps>(defaultValue)

const LayoutContextProvider = ({ children }: IProps): ReactElement => {
  const [modal, setModal] = useState<ModalType | null>(null)

  const openModal = (name: Modal, data: unknown) => setModal({ name, data })
  const closeModal = () => setModal(null)

  return (
    <LayoutContext.Provider
      value={{
        modal,
        openModal,
        closeModal,
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
}

export default LayoutContextProvider
