import React, { ReactElement, ReactNode } from 'react'

interface IProps {
  children: ReactNode
}

const Container = ({ children }: IProps): ReactElement => {
  return <div className="w-full lg:w-[1032px] mx-auto p-4 lg:p-0">{children}</div>
}

export default Container
