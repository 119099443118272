import { PlusIcon, XIcon } from '@heroicons/react/solid'
import {
  ChangeEvent,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react'
import { fileToBase64 } from '../../lib/helper'
import InputLabel from './InputLabel'
import 'react-image-crop/dist/ReactCrop.css'
// import ReactCrop, { Crop } from 'react-image-crop'

interface IProps {
  onChange?: (file: File | null) => void
  label?: string
  value?: File
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
}

const ImageInput = ({ onChange = () => null, label, value }: IProps) => {
  const [photoBase64, setPhotoBase64] = useState<null | string>(null)
  // const [crop, setCrop] = useState<Crop>()

  useEffect(() => {
    async function convertToBase64() {
      if (value) {
        const base64File = await fileToBase64(value)
        setPhotoBase64(base64File)
      } else {
        setPhotoBase64(null)
      }
    }
    convertToBase64()
  }, [value])

  const uploadInputRef = useRef() as MutableRefObject<HTMLInputElement>

  const handleFileInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0]
      onChange(file)
    }
  }

  const handleRemovePhoto = () => onChange(null)

  const handleUploadClick = () => {
    uploadInputRef.current.click()
  }

  if (photoBase64) {
    // return (
    //   <ReactCrop
    //     crop={crop}
    //     onChange={(c) => setCrop(c)}
    //     aspect={1}
    //     minWidth={300}
    //     minHeight={300}
    //   >
    //     <img src={photoBase64 as string} alt="Crop me" />
    //   </ReactCrop>
    // )
    return (
      <div className="relative w-full">
        <img src={photoBase64 as string} alt={''} />

        <XIcon
          onClick={handleRemovePhoto}
          className="absolute z-20 w-6 h-6 text-white cursor-pointer right-2 top-2"
        />
      </div>
    )
  }
  return (
    <div className="flex-grow w-full">
      {label && <InputLabel label={label} className="mb-1 ml-2" />}

      <div>
        <div
          className="flex items-center justify-center flex-grow-0 flex-shrink-0 gap-2 py-8 text-lg border border-dashed cursor-pointer border-blue-main photo-card"
          onClick={handleUploadClick}
        >
          <PlusIcon className="w-6 h-6 " />
          <div className="mt-2 font-semibold leading-5 text-center hover:text-blue-dark text-blue-main">
            {'Ajouter une photo'}
          </div>
        </div>

        <input
          type="file"
          accept="image/png, image/gif, image/jpeg"
          onChange={handleFileInputChange}
          className="hidden"
          ref={uploadInputRef}
        />
      </div>
    </div>
  )
}

export default ImageInput
