import { ReactNode } from 'react'
import IPlace from '../../interfaces/place.interface'
import InnerCard from '../common/InnerCard'
import PlacePhoto from './PlacePhoto'

interface IProps {
  place: IPlace
  footer?: ReactNode
}

const PlaceItem = ({ place, footer = null }: IProps) => {
  return (
    <InnerCard>
      <div className="flex gap-6">
        <PlacePhoto place={place} />

        <div>
          <h3 className="card-title">{place.name}</h3>

          <div className="mt-2">
            <div className="badge text-purple-main bg-purple-light">{`${place.offers.length} ${place.offers.length > 1 ? "offres": 'offre'}`}</div>
          </div>

          <div className="mt-6">{place.description}</div>
        </div>
      </div>

      {footer}
    </InnerCard>
  )
}

export default PlaceItem
