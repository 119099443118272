import { useQuery } from '@apollo/client'
import { ReactElement } from 'react'
import Card from '../components/common/Card'
import ErrorMessage from '../components/common/ErrorMessage'
import Loading from '../components/common/Loading'
import { GET_KPIS_QUERY } from '../graphql/dashboard.graphql'

const Dashboard = (): ReactElement => {
  const getKpisQuery = useQuery(GET_KPIS_QUERY)

  if (getKpisQuery.loading) {
    return <Loading />
  }

  if (getKpisQuery.error) {
    return <ErrorMessage message={getKpisQuery.error.message} />
  }

  const { usersCount, circuitsCount, placesCount, weeklyScansCount } = getKpisQuery.data.getKpis

  return (
    <div>
      <div className="flex items-end h-12">
        <h1>{'Tableau de bord'}</h1>
      </div>

      <div className="mt-8 gap-2 grid grid-cols-4">
        <Card>
          <div>
            <div className="text-center text-2xl font-bold">
              {
                usersCount
              }
            </div>
            <div className="opacity-60 text-sm mt-2 text-center">{"Utilisateurs total"}</div>
          </div>
        </Card>
        <Card>
          <div>
            <div className="text-center text-2xl font-bold">
              {
                circuitsCount
              }
            </div>
            <div className="opacity-60 text-sm mt-2 text-center">
              {`Circuits total`}
            </div>
          </div>
        </Card>
        <Card>
          <div>
            <div className="text-center text-2xl font-bold">

              {
                placesCount
              }
            </div>

            <div className="opacity-60 text-sm mt-2 text-center">
              {`Points d'intéret total`}
            </div>
          </div>
        </Card>
        <Card>
          <div>
            <div className="text-center text-2xl font-bold">
              {
                weeklyScansCount
              }
            </div>
            <div className="opacity-60 text-sm mt-2 text-center">{'Scans cette semaine'}</div>
          </div>
        </Card>
      </div>
    </div>
  )
}

export default Dashboard
