import clsx from 'clsx'
import dayjs from 'dayjs'
import Role from '../../enums/role.enum'
import IUser from '../../interfaces/user.interface'
import { displayUserRole, renderAcquisitionChannel, renderCategoryPreference, renderUserIdentity } from '../../lib/helper'
import InnerCard from '../common/InnerCard'

interface IProps {
  user: IUser
}

const UserItem = ({ user }: IProps) => {
  const roleClass =
    user.role === Role.ADMIN
      ? 'text-red-main bg-red-light'
      : 'text-blue-main bg-blue-light'
  const badgeRoleClass = clsx('badge', roleClass)

  return (
    <InnerCard>
      <div>
        <div className="flex gap-6">
          <div className="flex gap-2">
            <div className="card-title">{`${user.firstName} ${user.lastName}`}</div>
            <div className={badgeRoleClass}>{displayUserRole(user.role)}</div>
          </div>

        </div>
        <div className="mt-2 text-gray-dark">{`Compte créé le ${dayjs(user.createdAt).format('DD/MM/YYYY à HH:mm')}`}</div>

        {
          user.role === Role.USER && (
            <div className="mt-4 grid grid-cols-3">
              <div className="mt-4">
                <div className="font-semibold">{"Identité :"}</div>
                {
                  !!user.identity && (
                    <div>{renderUserIdentity(user.identity)}</div>
                  )
                }
              </div>

              <div className="mt-4">
                <div className="font-semibold">{"Préférences :"}</div>
                {
                  user.preferences.map(preference => (
                    <div key={preference}>{renderCategoryPreference(preference)}</div>
                  ))
                }
              </div>

              <div className="mt-4">
                <div className="font-semibold">{"Acquisition :"}</div>
                {
                  !!user.acquisitionChannel && (
                    <div>{renderAcquisitionChannel(user.acquisitionChannel)}</div>
                  )
                }
              </div>
            </div>
          )
        }
      </div>
    </InnerCard>
  )
}

export default UserItem
