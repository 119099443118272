import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import PrivateRoute from './middlewares/PrivateRoute'
import Dashboard from './pages/Dashboard'
import Circuits from './pages/Circuits'
import Partners from './pages/Partners'
import Place from './pages/Places'
import Login from './pages/Login'
import AuthContextProvider from './contexts/auth.context'
import apolloClient from './lib/apollo-client'
import { ApolloProvider } from '@apollo/client'
import LayoutContextProvider from './contexts/layout.context'
import ModalContainer from './components/layouts/ModalContainer'
import Users from './pages/Users'
import CircuitDetails from './pages/CircuitDetails'
import PartnerDetails from './pages/PartnerDetails'
import PlaceDetails from './pages/PlaceDetails'

const App = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <LayoutContextProvider>
        <AuthContextProvider>
          <>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<PrivateRoute />}>
                  <Route path="/tableau-de-bord" element={<Dashboard />} />
                  <Route path="/circuits" element={<Circuits />} />
                  <Route
                    path="/circuits/:circuitId"
                    element={<CircuitDetails />}
                  />
                  <Route path="/partenaires" element={<Partners />} />
                  <Route
                    path="/partenaires/:partnerId"
                    element={<PartnerDetails />}
                  />
                  <Route path="/utilisateurs" element={<Users />} />
                  <Route path="/points-dinteret" element={<Place />} />
                  <Route
                    path="/points-dinteret/:placeId"
                    element={<PlaceDetails />}
                  />
                </Route>

                <Route path="/" element={<PrivateRoute publicRoute />}>
                  <Route path="/login" element={<Login />} />
                </Route>

                <Route
                  path="*"
                  element={<Navigate to="/tableau-de-bord" replace />}
                />
              </Routes>
            </BrowserRouter>

            <ModalContainer />
          </>
        </AuthContextProvider>
      </LayoutContextProvider>
    </ApolloProvider>
  )
}

export default App
