import { ReactElement } from 'react'
import LoaderIcon from '../../icons/LoaderIcon'

const Loading = (): ReactElement => {
  return (
    <div
      data-testid="loader"
      className="flex items-center justify-center w-full h-60"
    >
      <LoaderIcon />
    </div>
  )
}

export default Loading
