import { useQuery } from '@apollo/client'
import Card from '../components/common/Card'
import ErrorMessage from '../components/common/ErrorMessage'
import Loading from '../components/common/Loading'
import UserItem from '../components/user/UserItem'
import { GET_ALL_USERS_QUERY } from '../graphql/user.graphql'
import IUser from '../interfaces/user.interface'

const Users = () => {
  const getAllUsersQuery = useQuery(GET_ALL_USERS_QUERY)

  if (getAllUsersQuery.loading) {
    return <Loading />
  }

  if (getAllUsersQuery.error) {
    return <ErrorMessage message={getAllUsersQuery.error.message} />
  }

  const users = getAllUsersQuery.data.getAllUsers

  return (
    <div>
      <div className="flex items-end justify-between h-12">
        <h1>{`Utilisateurs (${users.length})`}</h1>
      </div>

      <div className="mt-8">
        <Card className="flex flex-col gap-4">
          {users.map((user: IUser) => (
            <UserItem user={user} key={user._id} />
          ))}
        </Card>
      </div>
    </div>
  )
}

export default Users
